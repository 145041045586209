<template>
    <v-container class="fill-height" fluid>
        <v-card class="mx-auto elevation-4 formLogin" max-width="500"
        outlined shaped :loading="loading">
            <template slot="progress">
                <v-progress-linear color="primary" height="10" indeterminate>
                </v-progress-linear>
            </template>
            <v-img class="white--text align-end mt-6" height="160px" :src="logo" alt="Granja San Fernando" contain>
            </v-img>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, handleSubmit }">
                <v-form name="form" @submit.prevent="handleSubmit(handleLogin)">
                    <!-- <v-toolbar color="primary" dark text>
                        <v-toolbar-title class="font-weight-black">Iniciar sesión</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar> -->
                    <v-card-text>
                        <ValidationProvider name="username" rules="required" v-slot="{ errors, valid }">
                            <v-text-field label="Ingrese su usuario" name="username" prepend-icon="fas fa-user-secret"
                                type="text" v-model="user.username" counter :error-messages="errors"
                                :success="valid">
                            </v-text-field>
                        </ValidationProvider>
                        <Password :password.sync="user.password" name="password" label="Escriba su contraseña" rules="required"
                        :required="true" icon="fas fa-unlock-alt" :immediate="false"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="clear">Resetear</v-btn>
                        <v-btn class="ma-2" :loading="loading" 
                            color="primary" type="submit" :disabled="invalid || !validated">
                            Iniciar Sesión
                            <template v-slot:handleSubmit(handleLogin)>
                                <span>Cargando sistema..</span>
                            </template>
                        </v-btn>
                    </v-card-actions>
                    <v-card-text>
                        <v-alert border="left" colored-border dismissible :value="msj" type="warning"
                        elevation="2" v-if="message"> {{ message }}
                        </v-alert>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-container>
</template>
<script>
import User from "@/model/user";
import granja from '@/assets/logo_granja.jpeg'
import Password from "@/components/shared/Password.vue";
    export default {
        name: "Login",
        created () {
            if (this.loggedIn) {
                this.$store.commit('SET_LAYOUT', 'principal-layout');
                this.$router.replace('/controlPanel');
            }
        },
        components: { Password },
        data() {
            return {
                user    : new User("", ""),
                msj     : false,
                loading : false,
                logo: granja,
                message : ""
            }
        },
        methods: {
            async clear() {
                this.user = new User("", "");
                requestAnimationFrame(() => {
                    this.$refs.obs.reset();
                });
            },
            async handleLogin() {
                this.loading = true;
                this.$refs.obs.validate().then( isValid => {
                // this.$validator.validateAll().then((isValid) => {
                    if (!isValid) {
                        this.loading = false;
                        this.msj = true;
                        return;
                    }
                    if (this.user.username && this.user.password) {
                        this.$store.dispatch("auth/login", this.user).then( () => {
                            this.loading = true;
                            this.$store.commit('SET_LAYOUT', 'principal-layout')
                            this.$router.replace("/controlPanel");
                        },
                        (error) => {
                            this.loading = false;
                            this.msj = true
                            setTimeout( () => ( this.msj = false ), 4000)
                            this.message = error.response.data + " Error al iniciar sesion, validar sus datos, por favor.";
                        });
                    }
                });
            }
        },
        computed: {
            loggedIn(){
                return this.$store.state.auth.status.loggedIn;
            }
        },
    }
</script>